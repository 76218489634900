import React, { useState } from "react";
import ButtonSair from "@material-ui/core/Button";
import Button from "../generic/formElements/Button";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Redirect } from "react-router-dom";
import { SignoutLocation } from "@locations";

const useStyles = makeStyles(theme =>
  createStyles({
    button: {
      margin: theme.spacing(1)
    }
  })
);

const Forbidden = props => {
  const classes = useStyles();
  const [sair, setSair] = useState(false);

  return (
    <div style={{ textAlign: "center", marginTop: "15px" }}>
      <h2>Falha ao carregar esta funcionalidade.</h2>
      <h3>
        Clique em{" "}
        <ButtonSair
          variant="contained"
          color="primary"
          size="small"
          className={classes.button}
          startIcon={<ExitToAppIcon fontSize="small" />}
          onClick={() => setSair(true)}
        >
          Sair
        </ButtonSair>{" "}
        e faça o login novamente.
      </h3>
      <br />
      <br />
      <div>
        <Button label="Voltar" color="primary" click={props.history.goBack} />
      </div>
      {sair && <Redirect to={SignoutLocation.path} />}
    </div>
  );
};

export default Forbidden;
