import React from "react";
import MaskedInput from "react-text-mask";
import PropTypes from "prop-types";

export function CPFInput(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      placeholder="Digite o CPF"
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /[0-9]/,
        /\d/,
        /\d/,
        ".",
        /\d/,
        /\d/,
        /\d/,
        ".",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
      ]}
      placeholderChar={"\u2000"}
      showMask={false}
    />
  );
}

export function validarCPF(cpf) {
  if (!cpf) return false;
  if (cpf === "") return false;
  if ((cpf = cpf.replace(/[^\d]/g, "")).length !== 14) return false;

  if (
    !cpf ||
    cpf.length !== 14 ||
    cpf === "00000000000" ||
    cpf === "11111111111" ||
    cpf === "22222222222" ||
    cpf === "33333333333" ||
    cpf === "44444444444" ||
    cpf === "55555555555" ||
    cpf === "66666666666" ||
    cpf === "77777777777" ||
    cpf === "88888888888" ||
    cpf === "99999999999"
  )
    return false;

  return true;
}

CPFInput.propTypes = {
  inputRef: PropTypes.func.isRequired,
};
const mod11 = (num) => num % 11;
const NOT = (x) => !x;
const isEqual = (a) => (b) => b === a;
const mergeDigits = (num1, num2) => `${num1}${num2}`;
const getTwoLastDigits = (cpf) => `${cpf[9]}${cpf[10]}`;
const getCpfToCheckInArray = (cpf) => cpf.substr(0, 9).split("");
const generateArray = (length) => Array.from({ length }, (v, k) => k);

const isIn = (list) => (value) => list.findIndex((v) => value === v) >= 0;

const isSameDigitsCPF = (cpfFull) =>
  isIn(generateArray(10).map(generateStringSequence(11)))(cpfFull);

const generateStringSequence = (times) => (char) => `${char}`.repeat(times);

const toSumOfMultiplication = (total) => (result, num, i) =>
  result + num * total--;

const getSumOfMultiplication = (list, total) =>
  list.reduce(toSumOfMultiplication(total), 0);

const getValidationDigit = (total) => (cpf) =>
  getDigit(mod11(getSumOfMultiplication(cpf, total)));

const getDigit = (num) => (num > 1 ? 11 - num : 0);

export const getCpfOnlyNumbers = (cpfCompleto) => {
  return cpfCompleto.replace(/[^\d]+/g, "");
};
export const isValidCPF = (cpfCompleto) => {
  const cpfFullWithFormat = getCpfOnlyNumbers(cpfCompleto);
  const cpf = getCpfToCheckInArray(cpfFullWithFormat);
  const firstDigit = getValidationDigit(10)(cpf);
  const secondDigit = getValidationDigit(11)(cpf.concat(firstDigit));

  return isEqual(getTwoLastDigits(cpfFullWithFormat))(
    mergeDigits(firstDigit, secondDigit)
  );
};

export const isValid = (CPF) => NOT(isSameDigitsCPF(CPF)) && isValidCPF(CPF);
