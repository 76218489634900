import React, { useContext } from "react";
import NotificationsPopover from "../../../notifications/NotificationsPopover";
import { useLazyQuery } from "@apollo/client";
import NotificationContext from "../../../../context/notificationsContext";
import { getStorage } from "../../../generic/storage";
import querylistUnreadNotifications from "../../../notifications/listNotificationsGraphql";

export const MyNotificationsList = props => {
  const [dados, setDados] = useContext(NotificationContext);
  const [execute, { data }] = useLazyQuery(
    querylistUnreadNotifications,
    {
      variables: {
        pCodUsuarioRepresentacao: Number(
          getStorage("cod_usuario_representacao")
        ),
        pFlgLido: false
      }
    }
  );

  React.useEffect(() => {
    if (!dados.mensagemExecutouUmaVez) {
      execute();

      if (data) {
        setDados({
          ...dados,
          mensagensNaoLidas: data.fnMensageriaGetNotificacoes.nodes,
          mensagemExecutouUmaVez: true
        });
      }
    }
  }, [data, setDados, execute, dados]);

  return (
    <NotificationsPopover
      anchorEl={props.notificationsRef.current}
      notifications={dados.mensagensNaoLidas}
      onClose={props.handleNotificationsClose}
      open={props.openNotifications}
    />
  );
};