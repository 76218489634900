import { gql } from "@apollo/client";

export default gql`
  query Feed($pCodUsuarioRepresentacao: Int, $pFlgLido: Boolean) {
    fnMensageriaGetNotificacoes(
      pCodUsuarioRepresentacao: $pCodUsuarioRepresentacao
      pFlgLido: $pFlgLido
    ) {
      totalCount
    }
  }
`;
