import React, { Component, useContext } from "react";
import PropTypes from "prop-types";
import Login from "./login";
import Forbidden from "./Forbidden";
import { AuthContext } from "../../context/auth";
import { getStorage } from "../generic/storage";

const TestaLogin = props => {
  const auth = useContext(AuthContext);
  const isLoggedIn = auth.autenticado;
  const Component = props.component;

  if (!isLoggedIn) {
    return <Login />;
  } else if (!isAutorizado(props)) {
    return <Forbidden history={props.history} />;
  }
  return <Component />;
};

const isAutorizado = props => {
  let autorizado = false;
  const funcionalidades = JSON.parse(getStorage("funcionalidades"));

  if (!Array.isArray(funcionalidades)) {
    return false;
  }

  funcionalidades.forEach(url => {
    const arr = props.history.location.pathname.split("/");
    if (!url.dsc_url) return;
    if (`/${arr[1]}/${arr[2]}` === `${url.dsc_url.trim()}`) {
      autorizado = true;
    }

    if (`/${arr[1]}/${arr[2]}` === `/frontend${url.dsc_url.trim()}`) {
      autorizado = true;
    }
  });
  return autorizado;
};

export default function(ComposedComponent) {
  class Authentication extends Component {
    static contextTypes = {
      router: PropTypes.object
    };

    render() {
      return (
        <TestaLogin
          component={ComposedComponent}
          history={this.props.history}
          navigation={this.props.navigation}
        />
      );
    }
  }

  return Authentication;
}
