import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const styles = theme => ({
  button: {
    margin: theme.spacing(1)
  },
  input: {
    display: "none"
  }
});

function RaisedButtons(props) {
  const { classes, label, disabled } = props;

  return (
    <Fragment>
      <Button
        component={props.component}
        // to={props.to}
        variant="contained"
        color={props.color}
        onClick={props.click}
        className={classes.button}
        disabled={disabled}
      >
        {label}
      </Button>
    </Fragment>
  );
}

RaisedButtons.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(RaisedButtons);
