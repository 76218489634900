export const removeStorage = name => {
  try {
    const newKey = name + "_" + process.env.REACT_APP_COD_SISTEMA;
    /* eslint no-undef : 0 */
    localStorage.removeItem(newKey);
    localStorage.removeItem(`${newKey}_expiresIn`);
  } catch (e) {
    return false;
  }
  return true;
};

export const getStorage = key => {
  const newKey = key + "_" + process.env.REACT_APP_COD_SISTEMA;
  const now = Date.now();
  let expiresIn = localStorage.getItem(`${newKey}_expiresIn`);

  if (expiresIn === undefined || expiresIn === null) {
    expiresIn = 0;
  }

  if (expiresIn < now) {
    removeStorage(key);
    return null;
  }
  try {
    const value = localStorage.getItem(newKey);
    return value;
  } catch (e) {
    return null;
  }
};

export const setStorage = (key, value, expires) => {
  if (expires === undefined || expires === null) {
    expires = 60 * 60 * 24 * 180;
  } else {
    expires = Math.abs(expires);
  }

  const now = Date.now();
  const schedule = now + expires * 1000;
  try {
    const newKey = key + "_" + process.env.REACT_APP_COD_SISTEMA;
    localStorage.setItem(newKey, value);
    localStorage.setItem(`${newKey}_expiresIn`, schedule);
  } catch (e) {
    return false;
  }
  return true;
};
