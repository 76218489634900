import React, { useState, useEffect, useCallback } from "react";
import Icon from "@material-ui/core/Icon";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import { makeStyles } from "@material-ui/styles";
import { useHistory } from "react-router-dom";
import { getStorage } from "../../../generic/storage";

const useStyles = makeStyles((theme) => ({
  root: {
    "&:hover": {
      background: "#efefef",
    },
  },
  nested: {
    paddingLeft: theme.spacing(1),
  },
}));

const CollapsibleMenu = () => {
  const history = useHistory();
  const classes = useStyles();

  const [arrMenus, setArrMenus] = useState([]);
  const [menuHierarquizado, setMenuHierarquizado] = useState([]);

  const montaArrayMenuHierarquizado = useCallback((arrMenu, codMenuPai = null) => {
    return arrMenu
      .filter(menu => menu.cod_menu_pai === codMenuPai)
      .map(menu => ({
        ...menu,
        pages: montaArrayMenuHierarquizado(arrMenu, menu.cod_menu),
      }));
  }, []);

  useEffect(() => {
    const menus = JSON.parse(getStorage("menus")) || [];
    const formattedMenus = menus.map(menu => ({
      ...menu,
      key: menu.cod_menu,
      dsc_url: `${menu.dsc_url}`,
      flg_collapsed: false,
    }));
    setArrMenus(formattedMenus);
    setMenuHierarquizado(montaArrayMenuHierarquizado(formattedMenus));
  }, [montaArrayMenuHierarquizado]);

  const handleClickCollapsedArrow = (codMenu) => {
    setArrMenus(prevMenus => {
      const updatedMenus = prevMenus.map(menu =>
        menu.cod_menu === codMenu ? { ...menu, flg_collapsed: !menu.flg_collapsed } : menu
      );
      setMenuHierarquizado(montaArrayMenuHierarquizado(updatedMenus));
      return updatedMenus;
    });
  };

  const handleClickItemMenu = (dsc_url) => {
    history.push(dsc_url);
  };

  const itemMenu = (menu) => (
    <div className={classes.root} key={menu.key}>
      <ListItem button>
        <ListItemIcon onClick={() => handleClickItemMenu(menu.dsc_url)}>
          <Icon>{menu.dsc_icon}</Icon>
        </ListItemIcon>
        <ListItemText
          onClick={() => handleClickItemMenu(menu.dsc_url)}
          primary={menu.dsc_title_menu}
        />
        {menu.pages.length > 0 && collapsedArrow(menu)}
      </ListItem>
      {menu.pages.length > 0 && subMenu(menu.pages)}
    </div>
  );

  const subMenu = (subMenus) => {
    const menuPai = arrMenus.find(menu => menu.cod_menu === subMenus[0].cod_menu_pai);

    if (subMenus && menuPai) {
      return (
        <Collapse
          className={classes.nested}
          in={menuPai.flg_collapsed}
          timeout="auto"
          unmountOnExit
        >
          {subMenus.map(itemSubMenu => (
            <div key={itemSubMenu.key}>{itemMenu(itemSubMenu)}</div>
          ))}
        </Collapse>
      );
    }
  };

  const collapsedArrow = (menu) => (
    menu.flg_collapsed ? (
      <ExpandLess onClick={() => handleClickCollapsedArrow(menu.cod_menu)} />
    ) : (
      <ExpandMore onClick={() => handleClickCollapsedArrow(menu.cod_menu)} />
    )
  );

  return menuHierarquizado.map(menu => (
    <div key={menu.key}>{itemMenu(menu)}</div>
  ));
};

export default CollapsibleMenu;