import React, { useState } from "react";

const NotificationsContext = React.createContext([{}, () => {}]);

export const NotificationsProvider = props => {
  const [state, setState] = useState({
    totalExecutouUmaVez: false,
    mensagemExecutouUmaVez: false,
    total: 0,
    mensagens: [],
    mensagensNaoLidas: [],
    totalNaoLidas: 0,
    notificacoesTotaisExecutouUmaVez: false
  });

  return (
    <NotificationsContext.Provider value={[state, setState]}>
      {props.children}
    </NotificationsContext.Provider>
  );
};

export default NotificationsContext;
