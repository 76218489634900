import React, { Fragment, useEffect, useContext, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "react-router-dom";
import Icon from "@material-ui/core/Icon";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Drawer, Divider, Paper, Avatar, Typography } from "@material-ui/core";
import { Hidden } from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import useRouter from "../../../../utils/useRouter";
import { green } from "@material-ui/core/colors";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { getStorage } from "../../../generic/storage";
import { AuthContext } from "../../../../context/auth";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import axios from "axios";
import { authOptions } from "../../../generic/myAxios";
import { HomeLocation, SobreLocation } from "@locations";
import { isMobile } from "react-device-detect";
import PhoneIcon from "@material-ui/icons/SettingsPhone";
import CollapsibleMenu from "./CollapsibleMenu";
import packageJson from "../../../../../package.json";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    overflowY: "auto",
  },
  content: {
    padding: theme.spacing(2),
  },
  profile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "fit-content",
  },
  avatar: {
    width: 60,
    height: 60,
  },
  whatsAvatar: {
    color: "#fff",
    backgroundColor: green[500],
    width: 24,
    height: 24,
    cursor: "pointer",
  },
  name: {
    marginTop: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  navigation: {
    marginTop: theme.spacing(2),
  },
  version: {
    verticalAlign: "bottom",
    textAlign: "center",
  },
}));

const NavBar = (props) => {
  const { openMobile, onMobileClose, className, ...rest } = props;
  const auth = useContext(AuthContext);
  const classes = useStyles();
  const router = useRouter();

  const [imgAvatar, setImgAvatar] = useState("");
  const [pgAvatar, setPGAvatar] = useState("");
  const [nomeAvatar, setNomeAvatar] = useState("");

  useEffect(() => {
    if (openMobile) {
      onMobileClose && onMobileClose();
    }
    if (auth.autenticado) {
      getAvatar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.location.pathname, auth.autenticado]);

  const renderNavigationMenus = () => {
    if (!auth.autenticado) {
      return (
        <div>
          <List>
            <ListItem button component="a" href="https://www.cbm.df.gov.br" target="_blank">
              <ListItemText primary="Portal do CBMDF" />
            </ListItem>
            <ListItem button component="a" href="https://sistemas.cbm.df.gov.br" target="_blank">
              <ListItemText primary="Sistemas Corporativos" />
            </ListItem>
          </List>
        </div>
      );
    }

    const menus = JSON.parse(getStorage("menus")) || [];
    const arrMenus = menus.map(menu => ({
      dsc_title_menu: menu.dsc_title_menu,
      dsc_label_menu: menu.dsc_label_menu,
      cod_menu: menu.cod_menu,
      cod_menu_pai: menu.cod_menu_pai,
      dsc_icon: menu.dsc_icon,
      key: menu.cod_menu,
      dsc_url: menu.dsc_url || "",
      flg_collapsed: false,
    }));

    return <CollapsibleMenu arrMenus={arrMenus} />;
  };

  const DivProfile = () => (
    auth.autenticado ? (
      <div className={classes.profile}>
        <Avatar
          src={imgAvatar}
          className={classes.avatar}
          component={RouterLink}
          to={HomeLocation.path}
        />
        <Typography className={classes.name} variant="h4">
          {nomeAvatar}
        </Typography>
        <Typography variant="body2">{pgAvatar}</Typography>
      </div>
    ) : (
      <div />
    )
  );

  const getAvatar = async () => {
    try {
      const response = await axios.post(
        "/users/getAvatar",
        { cod_pessoa: auth.codPessoaAutenticado },
        authOptions()
      );

      setImgAvatar(response.data.base64 || "");
      setPGAvatar(response.data.sgl_posto_graduacao || "");
      setNomeAvatar(response.data.nom_guerra_servidor || "");
    } catch (error) {
      console.error(error);
    }
  };

  const navbarContent = (
    <div className={classes.content}>
      <DivProfile />
      <Divider className={classes.divider} />
      <nav className={classes.navigation}>
        {renderNavigationMenus()}
      </nav>
    </div>
  );

  const versionContent = (
    <ListItem button component={Link} to={SobreLocation.path}>
      <ListItemIcon>
        <Icon>info</Icon>
      </ListItemIcon>
      <ListItemText primary="Sobre" secondary={packageJson.version} />
    </ListItem>
  );

  const contactDitic = (
    <Fragment>
      {!window.ReactNativeWebView && (
        <ListItem
          button
          onClick={() => {
            const url = isMobile
              ? `whatsapp://send?phone=${process.env.REACT_APP_CELL_SUP_DITIC}`
              : `https://api.whatsapp.com/send?phone=${process.env.REACT_APP_CELL_SUP_DITIC}`;
            window.open(url, "_blank");
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', gap: '9px', padding: '2px' }}>
            <Avatar className={classes.whatsAvatar} style={{ width: 20, height: 20 }}>
              <PhoneIcon style={{ fontSize: 15 }} />
            </Avatar>
            <ListItemText primary="Suporte da DITIC" />
          </div>
        </ListItem>
      )}
    </Fragment>
  );

  return (
    <Fragment>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <div {...rest} className={clsx(classes.root, className)}>
            {navbarContent}
            <Divider className={classes.divider} />
            {versionContent}
            {contactDitic}
            <Divider className={classes.divider} />
          </div>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Paper {...rest} className={clsx(classes.root, className)} elevation={1} square>
          {navbarContent}
          <Divider className={classes.divider} />
          {versionContent}
          {contactDitic}
          <Divider className={classes.divider} />
        </Paper>
      </Hidden>
    </Fragment>
  );
};

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;