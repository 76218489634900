import React, { useContext } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import { onError } from "@apollo/client/link/error";
import { parse, stringify } from "flatted";
import DashboardLayout from "./components/Dashboard";
import Login from "./components/auth/login";
import RequireAuth from "./components/auth/required_auth";
import { subscribeWebPush } from "./subscription";
import Signout from "./components/auth/signout";
import { AuthContext } from "./context/auth";
import * as locations from "@locations";
import asyncComponent from "./components/AsyncComponent";
import {
  createHttpLink,
  ApolloLink,
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
} from "@apollo/client";
import {
  getStorage,
  removeStorage,
  setStorage,
} from "./components/generic/storage";

const AsyncChangePassword = asyncComponent(() =>
  import("./components/auth/changePasswordFormik")
);

const AsyncHome = asyncComponent(() => import("./components/home/home"));

const AsyncOperacional = asyncComponent(() =>
  import("./components/operacional")
);

const AsyncOperacionalGsvs = asyncComponent(() =>
  import("./components/time-map/index")
);

const AsyncOperacionalEscalas = asyncComponent(() =>
  import("./components/operacional/escalas")
);

const AsyncOperacionalPops = asyncComponent(() =>
  import("./components/operacional/pops")
);

const AsyncPessoal = asyncComponent(() => import("./components/pessoal"));

const AsyncPessoalAfastamentos = asyncComponent(() =>
  import("./components/pessoal/DadosFuncionais/afastamentos")
);

const AsyncPessoalAbono = asyncComponent(() =>
  import("./components/time-map/index")
);

const AsyncSaude = asyncComponent(() => import("./components/saude"));

const AsyncSaudeLaboratorios = asyncComponent(() =>
  import("./components/saude/laboratorio/UsuariosLaboratorio")
);

const AsyncResultado = asyncComponent(() =>
  import("./components/saude/laboratorio/Resultado")
);

const AsyncSaudeDescontos = asyncComponent(() =>
  import("./components/saude/descontos")
);

const AsyncConvenios = asyncComponent(() =>
  import("./components/saude/convenios")
);

const AsyncSaudeSituacaoDependentes = asyncComponent(() =>
  import("./components/saude/situacao-dependentes")
);

const AsyncSaudeAutorizacoes = asyncComponent(() =>
  import("./components/saude/autorizacoes")
);

const AsyncPodon = asyncComponent(() => import("./components/saude/podon"));

const AsyncSaudeMarcacaoOdonto = asyncComponent(() =>
  import("./components/saude/marcacao-odonto")
);

const AsyncMinhasSolicitacoes = asyncComponent(() =>
  import("./components/saude/minhas-solicitacoes")
);

const AsyncMinhasPontuacoes = asyncComponent(() =>
  import("./components/saude/minhas-pontuacoes")
);

const AsyncSaudeConsultas = asyncComponent(() =>
  import("./components/saude/consultas")
);

const AsyncPublicacoes = asyncComponent(() =>
  import("./components/publicacoes")
);

const AsyncSiapeBoletim = asyncComponent(() =>
  import("./components/publicacoes/siapeBoletim")
);

const AsyncEscalaNumerica = asyncComponent(() =>
  import("./components/pessoal/DadosFuncionais/EscalaNumericaNew")
);

const AsyncDadosPessoais = asyncComponent(() =>
  import("./components/pessoal/DadosPessoais")
);

const AsyncDadosBasicos = asyncComponent(() =>
  import("./components/pessoal/DadosPessoais/DadosBasicos")
);


const AsyncDadosFuncionais = asyncComponent(() =>
  import("./components/pessoal/DadosFuncionais")
);

const AsyncTempoServico = asyncComponent(() =>
  import("./components/pessoal/DadosFuncionais/TempoServico")
);

const AsyncDadosComplementares = asyncComponent(() =>
  import("./components/pessoal/DadosFuncionais/DadosComplementares")
);

const AsyncContato = asyncComponent(() =>
  import("./components/pessoal/DadosPessoais/contato")
);
const AsyncEndereco = asyncComponent(() =>
  import("./components/pessoal/DadosPessoais/Endereco")
);

const AsyncIdMilitar = asyncComponent(() =>
  import("./components/pessoal/IdentidadeMilitar")
);

const AsyncBoletins = asyncComponent(() =>
  import("./components/publicacoes/boletins")
);

const AsyncTimeMap = asyncComponent(() =>
  import("./components/time-map/index")
);

const AsyncUtilidades = asyncComponent(() => import("./components/utilidades"));

const AsyncTelefones = asyncComponent(() =>
  import("./components/utilidades/telefones")
);

const AsyncNotificacoes = asyncComponent(() =>
  import("./components/Notificacoes")
);

const AsyncGestaoNotificacoes = asyncComponent(() =>
  import("./components/utilidades/gestaoNotificacoes")
);

const AsyncSobre = asyncComponent(() => import("./components/sobre"));

const AsyncAntiguidade = asyncComponent(() =>
  import("./components/antiguidade")
);

const AsyncComando = asyncComponent(() =>
  import("./components/antiguidade/comando")
);

const AsyncOrdenamento = asyncComponent(() =>
  import("./components/antiguidade/ordenamento")
);

const AsyncAtualizaConvenios = asyncComponent(() =>
  import("./components/atualiza-convenios")
);

const AsyncAtualizaTelefones = asyncComponent(() =>
  import("./components/atualiza-telefones")
);
const AsyncGsvPrincipal = asyncComponent(() =>
  import("./components/operacional/gsv-principal")
);
const AsyncGsvMarcacao = asyncComponent(() =>
  import("./components/operacional/gsv-principal/gsv-marcacao")
);

const AsyncGsvInscricao = asyncComponent(() =>
  import("./components/operacional/gsv-principal/gsv-inscricao")
);

const AsyncGsvPreviaEscala = asyncComponent(() =>
  import("./components/operacional/gsv-principal/gsv-previa-escala")
);

const AsyncGsvJustificativaFalta = asyncComponent(() =>
  import("./components/operacional/gsv-principal/gsv-justificativa-falta")
);

const AsyncGsvInfoVagas = asyncComponent(() =>
  import("./components/operacional/gsv-principal/gsv-info-vagas")
);
const AsyncGsvAgenda = asyncComponent(() =>
  import("./components/operacional/gsv-principal/gsv-agenda")
);
const AsyncGsvAplicacaoCriterios = asyncComponent(() =>
import("./components/operacional/gsv-principal/gsv-aplicacao-criterios")
);

const AsyncCursos = asyncComponent(() =>
import("./components/pessoal/DadosFuncionais/cursos")
);

const NoMatch = ({ location }) => (
  <div>
    <h3>
      Página não encontrada: <code> {location.pathname} </code>
    </h3>
  </div>
);

const Rotas = (token) => {
  return (
    <Switch>
      <Route
        exact
        path={locations.DefaultLocation.path}
        render={() => {
          return (
            <AuthContext.Consumer>
              {(context) => {
                if (context.autenticado === true) {
                  return <Redirect to={locations.HomeLocation.path} />;
                } else {
                  return <Redirect to={locations.LoginLocation.path} />;
                }
              }}
            </AuthContext.Consumer>
          );
        }}
      />
      <Route
        exact
        path={locations.LoginLocation.path}
        render={() => {
          return (
            <AuthContext.Consumer>
              {(context) => {
                if (context.autenticado === true) {
                  return <Redirect to={locations.HomeLocation.path} />;
                } else {
                  return <Login />;
                }
              }}
            </AuthContext.Consumer>
          );
        }}
      />
      <Route path={locations.SignoutLocation.path} component={Signout} />
      <Route
        path={locations.TrocaSenhaLocation.path}
        component={AsyncChangePassword}
      />
      <Route
        path={locations.HomeLocation.path}
        component={RequireAuth(AsyncHome)}
      />
      <Route
        path={locations.OperacionalLocation.path}
        component={RequireAuth(AsyncOperacional)}
      />
      <Route
        path={locations.GsvsLocation.path}
        component={RequireAuth(AsyncOperacionalGsvs)}
      />
      <Route
        path={locations.PopsLocation.path}
        component={RequireAuth(AsyncOperacionalPops)}
      />
      <Route
        path={locations.EscalasLocation.path}
        component={AsyncOperacionalEscalas}
      />
      <Route
        path={locations.PessoalLocation.path}
        component={RequireAuth(AsyncPessoal)}
      />
      <Route
        path={locations.AfastamentosLocation.path}
        component={RequireAuth(AsyncPessoalAfastamentos)}
      />
      <Route
        path={locations.AbonoLocation.path}
        component={RequireAuth(AsyncPessoalAbono)}
      />
      <Route
        path={locations.SaudeLocation.path}
        component={RequireAuth(AsyncSaude)}
      />
      <Route
        path={locations.PodonLocation.path}
        component={RequireAuth(AsyncPodon)}
      />
      <Route
        path={locations.MinhasSolicitacoesLocation.path}
        component={RequireAuth(AsyncMinhasSolicitacoes)}
      />
      <Route
        path={locations.MinhasPontuacoesLocation.path}
        component={RequireAuth(AsyncMinhasPontuacoes)}
      />
      <Route
        path={locations.LaboratoriosLocation.path}
        component={RequireAuth(AsyncSaudeLaboratorios)}
      />
      <Route
        path={locations.ResultadoLocation.path}
        component={AsyncResultado}
      />
      <Route
        path={locations.DescontosLocation.path}
        component={RequireAuth(AsyncSaudeDescontos)}
      />
      <Route
        path={locations.ConsultasLocation.path}
        component={RequireAuth(AsyncSaudeConsultas)}
      />
      <Route
        path={locations.SituacaoDependentesLocation.path}
        component={RequireAuth(AsyncSaudeSituacaoDependentes)}
      />
      <Route
        path={locations.AutorizacoesLocation.path}
        component={RequireAuth(AsyncSaudeAutorizacoes)}
      />
      <Route
        path={locations.MarcacaoOdontoLocation.path}
        component={RequireAuth(AsyncSaudeMarcacaoOdonto)}
      />
      <Route
        path={locations.PublicacoesLocation.path}
        component={RequireAuth(AsyncPublicacoes)}
      />
      <Route
        path={locations.SiapeBoletimLocation.path}
        component={RequireAuth(AsyncSiapeBoletim)}
      />
      <Route
        path={locations.EscalaNumericaLocation.path}
        component={RequireAuth(AsyncEscalaNumerica)}
      />
      <Route
        path={locations.DadosPessoaisLocation.path}
        component={RequireAuth(AsyncDadosPessoais)}
      />

      <Route
        path={locations.DadosBasicosLocation.path}
        component={RequireAuth(AsyncDadosBasicos)}
      />
      <Route
        path={locations.DadosFuncionaisLocation.path}
        component={RequireAuth(AsyncDadosFuncionais)}
      />
      <Route path={locations.ContatoLocation.path} component={AsyncContato} />
      <Route path={locations.EnderecoLocation.path} component={AsyncEndereco} />
      <Route
        path={locations.IdMilitarLocation.path}
        component={RequireAuth(AsyncIdMilitar)}
      />
      <Route
        path={locations.BoletinsLocation.path}
        component={RequireAuth(AsyncBoletins)}
      />
      <Route path={locations.TimeMapLocation.path} component={AsyncTimeMap} />
      <Route
        path={locations.UtilidadesLocation.path}
        component={RequireAuth(AsyncUtilidades)}
      />
      <Route
        path={locations.ConveniosLocation.path}
        component={RequireAuth(AsyncConvenios)}
      />
      <Route
        path={locations.TelefonesLocation.path}
        component={RequireAuth(AsyncTelefones)}
      />
      <Route
        path={locations.NotificacoesLocation.path + "/:id"}
        component={RequireAuth(AsyncNotificacoes)}
      />
      <Route
        path={locations.NotificacoesLocation.path}
        component={RequireAuth(AsyncNotificacoes)}
      />
      <Route
        path={locations.SobreLocation.path}
        component={RequireAuth(AsyncSobre)}
      />
      <Route
        path={locations.GestaoNotificacoesLocation.path}
        component={RequireAuth(AsyncGestaoNotificacoes)}
      />
      <Route
        path={locations.GestaoNotificacoesLocation.path}
        component={RequireAuth(AsyncGestaoNotificacoes)}
      />
      <Route
        path={locations.AntiguidadeLocation.path}
        component={RequireAuth(AsyncAntiguidade)}
      />
      <Route
        path={locations.ComandoLocation.path}
        component={RequireAuth(AsyncComando)}
      />
      <Route
        path={locations.OrdenamentoLocation.path}
        component={RequireAuth(AsyncOrdenamento)}
      />
      <Route
        path={locations.AtualizaConveniosLocation.path}
        component={RequireAuth(AsyncAtualizaConvenios)}
      />
      <Route
        path={locations.AtualizaTelefonesLocation.path}
        component={RequireAuth(AsyncAtualizaTelefones)}
      />
      <Route
        path={locations.TempoServicoLocation.path}
        component={RequireAuth(AsyncTempoServico)}
      />
      <Route
        path={locations.DadosComplementaresLocation.path}
        component={RequireAuth(AsyncDadosComplementares)}
      />
      <Route
        path={locations.GsvPrincipalLocation.path}
        component={RequireAuth(AsyncGsvPrincipal)}
      />
      <Route
        path={locations.GsvMarcacaoLocation.path}
        component={RequireAuth(AsyncGsvMarcacao)}
      />
      <Route
        path={locations.GsvInscricaoLocation.path}
        component={RequireAuth(AsyncGsvInscricao)}
      />
      <Route
        path={locations.CursosLocation.path}
        component={RequireAuth(AsyncCursos)}
      />
      <Route
        path={locations.GsvPreviaEscalaLocation.path}
        component={RequireAuth(AsyncGsvPreviaEscala)}
      />
      <Route
        path={locations.GsvJustificativaFaltaLocation.path}
        component={RequireAuth(AsyncGsvJustificativaFalta)}
      />
      <Route
        path={locations.GsvInfoVagasLocation.path}
        component={RequireAuth(AsyncGsvInfoVagas)}
      />
      <Route
        path={locations.GsvAgendaLocation.path}
        component={RequireAuth(AsyncGsvAgenda)}
      />
      <Route
        path={locations.GsvAplicacaoCriteriosLocation.path}
        component={RequireAuth(AsyncGsvAplicacaoCriterios)}
      />

      <Route component={NoMatch} />
    </Switch>
  );
};

const TelaPrincipal = (token) => {
  const auth = useContext(AuthContext);

  function isWebPushSupportedOnIOS() {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const isChrome = /CriOS/i.test(navigator.userAgent);

    const iOSVersionMatch = navigator.userAgent.match(/OS (\d+)_(\d+)_?(\d+)?/);
    if (iOSVersionMatch) {
      const majorVersion = parseInt(iOSVersionMatch[1], 10);
      const minorVersion = parseInt(iOSVersionMatch[2], 10);

      if (majorVersion > 16 || (majorVersion === 16 && minorVersion >= 4)) {
        return isSafari || isChrome;
      }
    }

    return false;
  }

  function isWebPushSupported() {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const isChrome = /CriOS/i.test(navigator.userAgent) || /Chrome/i.test(navigator.userAgent);

    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      return isWebPushSupportedOnIOS();
    }

    return isSafari || isChrome;
  }

  const supportsWebPush = isWebPushSupported();

  if (auth.autenticado) {
    if (supportsWebPush) {
      if (
        "Notification" in window &&
        Notification.permission === "default"
      ) {
        Notification.requestPermission().then((status) => {
          if (status === "granted") {
            subscribeWebPush();
          }
        });
      }

      if (
        "Notification" in window &&
        Notification.permission === "granted" &&
        getStorage("webpushtoken") !== true &&
        getStorage("webpushtoken") !== "true"
      ) {
        subscribeWebPush();
      }

      if ("Notification" in window && Notification.permission === "denied") {
        setStorage("webpushtoken", false);
      }
    }
  }

  if (auth.showNavBar) {
    return (
      <BrowserRouter basename={process.env.REACT_APP_BASENAME_FRONTEND}>
        <DashboardLayout>{Rotas(token)}</DashboardLayout>
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter basename={process.env.REACT_APP_BASENAME_FRONTEND}>
      {Rotas(token)}
    </BrowserRouter>
  );
};

export const RouteTo = () => {
  const auth = useContext(AuthContext);
  const token = getStorage("token");

  let urlGraphql = "";
  if (process.env.REACT_APP_BASE_URL_BACKEND.endsWith("/")) {
    urlGraphql = `${process.env.REACT_APP_BASE_URL_BACKEND}graphql`;
  } else {
    urlGraphql = `${process.env.REACT_APP_BASE_URL_BACKEND}/graphql`;
  }

  const httpLink = createHttpLink({
    uri: urlGraphql,
  });

  const middlewareLink = new ApolloLink((operation, forward) => {
    const omitTypename = (key, value) =>
      key === "__typename" ? undefined : value;
    if (operation.variables && !operation.getContext().hasUpload) {
      operation.variables = parse(stringify(operation.variables), omitTypename);
    }
    operation.setContext({
      headers: {
        authorization: localStorage.getItem("token_" + process.env.REACT_APP_COD_SISTEMA) || null,
      },
    });
    return forward(operation);
  });

  const mlink = middlewareLink.concat(httpLink);

  const errorLink = onError(({ networkError, graphQLErrors }) => {
    if (
      (process.env.NODE_ENV !== "development" &&
        networkError.statusCode === 401) ||
      networkError.statusCode === 403 ||
      graphQLErrors
    ) {
      removeStorage("token");
      removeStorage("cod_pessoa");

      if (getStorage("cod_pessoa") || getStorage("token") || auth.autenticado) {
        auth.alteraAutenticacao(false);
      }
    }
  });

  const link = errorLink.concat(mlink);

  const client = new ApolloClient({
    link,
    cache: new InMemoryCache(),
  });

  if (auth.autenticado === false) {
    if (getStorage("cod_pessoa")) {
      auth.alteraAutenticacao(true, getStorage("cod_pessoa"));
    }
  }

  return (
    <ApolloProvider client={client}>{TelaPrincipal(token)}</ApolloProvider>
  );
};
