import * as Yup from "yup";

/*eslint no-template-curly-in-string: "off"*/

Yup.setLocale({
  mixed: {
    required: "Preencha esse campo para continuar"
  },
  string: {
    email: "Preencha um e-mail válido",
    min: "Valor muito curto (mínimo ${min} caracteres)",
    max: "Valor muito longo (máximo ${max} caracteres)"
  },
  number: {
    min: "Valor inválido (deve ser maior ou igual a ${min})",
    max: "Valor inválido (deve ser menor ou igual a ${max})"
  }
});

export default Yup;
