import { getStorage } from "./storage";

export const authOptions = () => {
  const token = getStorage("token");
  const options = {
    baseURL: `${process.env.REACT_APP_BASE_URL_BACKEND}`,
    timeout: 220000,
    crossdomain: true,
    headers: {
      authorization: token,
      "x-access-token": token
    }
  };

  return options;
};

export const authOptionsFile = () => {
  return {
    ...authOptions(),
    responseType: "blob"
  };
};
