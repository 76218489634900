import Location from "react-app-location";

//Default
export const DefaultLocation = new Location("/");
export const HomeLocation = new Location("/frontend/home");
export const LoginLocation = new Location("/frontend/auth/login");
export const SignoutLocation = new Location("/frontend/auth/signout");
export const TrocaSenhaLocation = new Location("/frontend/users/trocasenha");

//Operacional
export const OperacionalLocation = new Location("/frontend/operacional");
export const EscalasLocation = new Location("/frontend/escalas");
export const GsvsLocation = new Location("/frontend/gsvs");
export const PopsLocation = new Location("/frontend/pops");

//Pessoal
export const EscalaNumericaLocation = new Location("/frontend/escala-numerica");
export const PessoalLocation = new Location("/frontend/pessoal");
export const AfastamentosLocation = new Location("/frontend/afastamentos");
export const AbonoLocation = new Location("/frontend/afastamentos");
export const DadosPessoaisLocation = new Location("/frontend/dados-pessoais");
export const DadosBasicosLocation = new Location("/frontend/dados-basicos");
export const DadosFuncionaisLocation = new Location(
  "/frontend/dados-funcionais"
);
export const TempoServicoLocation = new Location("/frontend/tempo-servico");
export const DadosComplementaresLocation = new Location(
  "/frontend/dados-complementares"
);
export const IdMilitarLocation = new Location("/frontend/identidade-militar");
export const CursosLocation = new Location("/frontend/cursos");
export const EnderecoLocation = new Location("/frontend/endereco");
export const ContatoLocation = new Location("/frontend/contato");

//Saude
export const SaudeLocation = new Location("/frontend/saude");
export const LaboratoriosLocation = new Location("/frontend/laboratorios");
export const ResultadoLocation = new Location("/frontend/resultado");
export const ConveniosLocation = new Location("/frontend/convenios");
export const DescontosLocation = new Location("/frontend/descontos");
export const SituacaoDependentesLocation = new Location(
  "/frontend/situacao-dependentes"
);
export const AutorizacoesLocation = new Location("/frontend/autorizacoes");

//Odonto
export const PodonLocation = new Location("/frontend/podon");
export const MarcacaoOdontoLocation = new Location("/frontend/marcacao-odonto");
export const MinhasSolicitacoesLocation = new Location("/frontend/minhas-solicitacoes-podon");
export const ConsultasLocation = new Location("/frontend/consultas");
export const MinhasPontuacoesLocation = new Location("/frontend/minhas-pontuacoes-podon");

//Publicações
export const PublicacoesLocation = new Location("/frontend/publicacoes");
export const SiapeBoletimLocation = new Location("/frontend/siapeBoletim");
export const BoletinsLocation = new Location("/frontend/boletins");

//Notificações
export const NotificacoesLocation = new Location("/frontend/notificacoes");

//Map
export const TimeMapLocation = new Location("/frontend/time-map");

//Utilidades
export const UtilidadesLocation = new Location("/frontend/utilidades");
export const TelefonesLocation = new Location("/frontend/telefones");
export const GestaoNotificacoesLocation = new Location(
  "/frontend/gestao-notificacoes"
);

//Outros
export const SobreLocation = new Location("/frontend/sobre");
export const AntiguidadeLocation = new Location("/frontend/antiguidade");
export const ComandoLocation = new Location("/frontend/comando");
export const OrdenamentoLocation = new Location("/frontend/ordenamento");
export const AtualizaConveniosLocation = new Location(
  "/frontend/atualiza-convenios"
);
export const AtualizaTelefonesLocation = new Location(
  "/frontend/atualiza-telefones"
);

//GSV
export const GsvPrincipalLocation = new Location("/frontend/gsv-principal");
export const GsvMarcacaoLocation = new Location("/frontend/gsv-marcacao");
export const GsvInscricaoLocation = new Location("/frontend/gsv-inscricao");
export const GsvPreviaEscalaLocation = new Location(
  "/frontend/gsv-previa-escala"
);
export const GsvJustificativaFaltaLocation = new Location(
  "/frontend/gsv-justificativa-falta"
);
export const GsvInfoVagasLocation = new Location("/frontend/gsv-info-vagas");
export const GsvAplicacaoCriteriosLocation = new Location(
  "/frontend/gsv-aplicacao-criterios"
  );
  export const GsvAgendaLocation = new Location("/frontend/gsv-agenda");