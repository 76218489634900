import React, { useEffect } from "react";
import { NotificationsProvider } from "./context/notificationsContext";
import { RouteTo } from "./rotas";
import theme from "./theme";
import { ThemeProvider } from "@material-ui/styles";
import "./assets/scss/index.scss";
import { Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
const options = {
  position: "middle",
  timeout: 7001,
  offset: "10",
  transition: "scale"
};

const App = props => {
  useEffect(() => {
    const ele = document.getElementById("ipl-progress-indicator");
    if (ele) {
      setTimeout(() => {
        ele.classList.add("available");
        setTimeout(() => {
          ele.outerHTML = "";
        }, 1000);
      }, 500);
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <NotificationsProvider>
        <AlertProvider template={AlertTemplate} {...options}>
          <RouteTo props={props} />
        </AlertProvider>
      </NotificationsProvider>
    </ThemeProvider>
  );
};

export default App;
