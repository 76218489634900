import axios from "axios";
import { authOptions } from "../generic/myAxios";
import { setStorage } from "../generic/storage";
import * as serviceWorker from "../../registerServiceWorker";

async function getServiceWorker() {
  const registrations = await navigator.serviceWorker.getRegistrations();
  return registrations.find(registration => registration.active || registration.waiting);
}

const signinUser = async (
  cpf,
  senha,
  autenticar,
  recaptchaValue
) => {
  try {
    // Obtém o usuário representação
    const response = await axios.get(
      `/users/getRepsByCPF?num_cpf_pessoa=${cpf}`,
      authOptions()
    );
    
    // Obtém o token JWT e os dados do usuário logado
    if (response.data.success === true) {
      const tokenJWT = await axios.post(
        "/users/createTokenBySenha",
        {
          num_cpf_pessoa: cpf,
          vlr_senha: senha,
          cod_usuario_representacao: response.data.message[0].cod_usuario_representacao.toString(),
          cod_sistema: process.env.REACT_APP_COD_SISTEMA,
          recaptchaValue: recaptchaValue
        },
        authOptions()
      );

      if (tokenJWT.data.success === true) {
        //Seta no Storage os dados
        setStorage("token", tokenJWT.data.token);
        setStorage("nom_completo_pessoa", tokenJWT.data.message.nom_completo_pessoa);
        setStorage("vlr_identificacao_unica", tokenJWT.data.message.vlr_identificacao_unica);
        setStorage("cod_usuario", tokenJWT.data.message.cod_usuario);
        setStorage("cod_usuario_representacao", tokenJWT.data.message.cod_usuario_representacao);
        setStorage("num_cpf_pessoa", tokenJWT.data.message.num_cpf_pessoa);
        setStorage("num_telefone", tokenJWT.data.message.num_telefone);
        setStorage("cod_pessoa", tokenJWT.data.message.cod_pessoa);
        setStorage("cod_posto_graduacao", tokenJWT.data.message.cod_posto_graduacao);
        setStorage("cod_quadro", tokenJWT.data.message.cod_quadro);
        setStorage("cod_pessoa_juridica", tokenJWT.data.message.cod_pessoa_juridica);
        setStorage("sgl_posto_graduacao", tokenJWT.data.message.sgl_posto_graduacao);
        setStorage("nom_guerra", tokenJWT.data.message.nom_guerra_servidor);

        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage(tokenJWT.data.message.cod_usuario);
          window.ReactNativeWebView.postMessage(tokenJWT.data.token);
        } 

        const menu = await axios.get("/users/getMenus", authOptions());
        const funcionalidades = await axios.get("/users/getFuncionalidades", authOptions());

        if (menu.data.success === true && funcionalidades.data.success === true) {
          setStorage("menus", JSON.stringify(menu.data.message));
          setStorage("funcionalidades", JSON.stringify(funcionalidades.data.message));
          autenticar(true, tokenJWT.data.message.cod_pessoa);

          // Chamar a função para forçar a atualização do app após o login
          const registration = await getServiceWorker();
          if (registration) {
            serviceWorker.forceUpdateAfterLogin(registration);
          }

        } else {
          return { cod_error: 3, msg: menu.data.message };
        }
      } else {
        return { cod_error: 2, msg: "DADOS INCORRETOS!" };
      }
    } else {
      return { cod_error: 1, msg: "DADOS INCORRETOS!" };
    }
  } catch (e) {
    return { cod_error: 3, msg: e.message };
  }
};

export { signinUser };