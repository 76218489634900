import React, { Component } from "react";
export const AuthContext = React.createContext();

class AuthProvider extends Component {
  state = {
    autenticado: false,
    codPessoaAutenticado: null,
    showNavBar: true,
    file: null,
    alteraAutenticacao: (flgAutenticacao, codPessoaAutenticado) => {
      this.setState({ autenticado: flgAutenticacao, codPessoaAutenticado });
    },
    alteraNavBar: flg => {
      this.setState({ showNavBar: flg });
    },
    setFile: file => {
      this.setState({ file });
    }
  };

  render() {
    return (
      <AuthContext.Provider value={this.state}>
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}
export default AuthProvider;
