import React, { Component } from "react";
import _ from "lodash";
import { Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import { Formik } from "formik";
import { MyLoader, cpf } from "@lucasapereira/lib-react";
import { FormLogin } from "./formLogin";
import { HomeLocation } from "@locations";
import validationSchema from "./formValidation";

const options = {
  position: "bottom center",
  timeout: 5000,
  offset: "30px",
  transition: "scale"
};
class Login extends Component {
  redirectToMainPage(props) {
    if (props.auth.autenticado) {
      props.history.push(HomeLocation.path);
    }
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="alert alert-danger">{this.props.errorMessage}</div>
      );
    }
  }

  getEmpresas = values => {
    if (
      !_.isEmpty(this.props.cpf) &&
      !this.props.limpa_tela &&
      !cpf(this.props.cpf)
    ) {
      this.props.getEmpresas(values);
    }
  };

  render() {
    const values = { cpf: "", password: "" };
    
    if (this.props.loading) {
      return <MyLoader />;
    }

    return (
      <div className="container">
        <div className="divFormLogin">
          <AlertProvider template={AlertTemplate} {...options}>
            <Formik initialValues={values} validationSchema={validationSchema}>
              {props => <FormLogin {...props} />}
            </Formik>
          </AlertProvider>
        </div>
        <br />
      </div>
    );
  }
}

export default Login;
