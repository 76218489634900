/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect, useContext } from "react";
import { useLazyQuery } from "@apollo/client";
import Badge from "@material-ui/core/Badge";
import queryUnReadNotificationsCount from "../../../notifications/unReadNotificationGraphql";
import { getStorage } from "../../../generic/storage";
import { MyLoader } from "@lucasapereira/lib-react";
import NotificationsIcon from "@material-ui/icons/Notifications";
import NotificationContext from "../../../../context/notificationsContext";

export const MyBadge = () => {
  const [dados, setDados] = useContext(NotificationContext);
  const [execute, { data }] = useLazyQuery(
    queryUnReadNotificationsCount,
    {
      variables: {
        pCodUsuarioRepresentacao: Number(
          getStorage("cod_usuario_representacao")
        ),
        pFlgLido: false
      }
    }
  );

  React.useEffect(() => {
    if (!dados.totalExecutouUmaVez) {
      execute();
      if (data) {
        setDados({
          ...dados,
          totalNaoLidas: data.fnMensageriaGetNotificacoes.totalCount,
          totalExecutouUmaVez: true
        });
      }
    }
  }, [data, setDados, execute, dados]);

  return (
    <Badge badgeContent={dados.totalNaoLidas} color="secondary">
      <NotificationsIcon />
    </Badge>
  );
};
