// Código responsável pelo registro e atualização do Service Worker

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
  window.location.hostname === "[::1]" ||
  window.location.hostname.match(
    /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
  )
);
  
export function register(config) {
  if (process.env.NODE_ENV === "production" && "serviceWorker" in navigator) {
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    
    if (publicUrl.origin !== window.location.origin) {
      return;
    }

    window.addEventListener("load", () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
      if (isLocalhost) {
        checkValidServiceWorker(swUrl, config);
        navigator.serviceWorker.ready.then(() => {
          console.log("Este aplicativo está sendo servido em cache-primeiro por um service worker.");
        });
      } else {
        registerValidSW(swUrl, config);
      }
    });
  }
}
  
function registerValidSW(swUrl, config) {
  navigator.serviceWorker
    .register(swUrl)
    .then((registration) => {
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (installingWorker == null) {
          return;
        }
        installingWorker.onstatechange = () => {
          if (installingWorker.state === "installed") {
            if (navigator.serviceWorker.controller) {
              console.log("Novo conteúdo está disponível e será utilizado quando todas as abas desta página forem fechadas.");

              if (config && config.onUpdate) {
                config.onUpdate(registration);
              }

              forceUpdateAfterLogin(registration);

            } else {
              console.log("Conteúdo foi armazenado em cache para uso offline.");

              if (config && config.onSuccess) {
                config.onSuccess(registration);
              }
            }
          }
        };
      };
    })
    .catch((error) => {
      console.error("Erro durante o registro do service worker:", error);
    });
}
  
function checkValidServiceWorker(swUrl, config) {
  fetch(swUrl, {
    headers: { "Service-Worker": "script" },
  })
    .then((response) => {
      const contentType = response.headers.get("content-type");
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf("javascript") === -1)
      ) {
        navigator.serviceWorker.ready.then((registration) => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        registerValidSW(swUrl, config);
      }
    })
    .catch(() => {
      console.log("Nenhuma conexão com a internet encontrada. O aplicativo está rodando no modo offline.");
    });
}
  
export function unregister() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error(error.message);
      });
  }
}
  
export function forceUpdateAfterLogin(registration) {
  if (registration.waiting) {
    registration.waiting.postMessage({ type: "SKIP_WAITING" });

    registration.waiting.addEventListener("statechange", (event) => {
      if (event.target.state === "activated") {
        console.log("Nova versão ativada. Recarregando a página.");

        const currentUrl = window.location.href.split('?')[0];
        window.location.href = `${currentUrl}?t=${new Date().getTime()}`;
      }
    });
  }
}